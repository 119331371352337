<template>
  <div class="rotating">
    <vs-icon icon="icon-loader" icon-pack="feather"></vs-icon>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>
